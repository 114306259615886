import { Fragment } from "react"
import styled from "styled-components"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchIndoorClimateMonitoringPresets,
  useFetchNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { DropdownListButton } from "src/ui/Button/DropdownListButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"
import NoiseMonitoringIcon from "src/ui/icons/noise-monitoring.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TMonitoringProfile = {
  id: string
  name: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  presets: { id: string; name: string }[]
}

export function HomeMonitoringPresets() {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const org = useOrganization()

  const hasAdminAccess = org.orgAccessLogic.hasAdminAccess

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets()
  const noisePresets = fetchNoiseMonitoringPresets.data?.profiles || []

  const fetchICMPresets = useFetchIndoorClimateMonitoringPresets()
  const icmPresets = fetchICMPresets.data?.profiles || []

  const monitoringProfiles: TMonitoringProfile[] = [
    {
      id: "noise-profiles",
      name: "Noise monitoring",
      icon: NoiseMonitoringIcon,
      presets: noisePresets,
    },
    {
      id: "icm-profiles",
      name: "Indoor climate",
      icon: IndoorClimateIcon,
      presets: icmPresets,
    },
  ]

  const header = [
    <div key="setting"> {tPlaceholder("Settings for")}</div>,
    <div key="preset"> {tPlaceholder("Applied preset")}</div>,
    <div key="open-preset-link"></div>,
  ]

  const rows = monitoringProfiles.map((p) => {
    const Icon = p.icon

    return (
      <Fragment key={p.id}>
        <SettingsPresetBox>
          <Icon width={24} height={24} />

          <InternalLink to={Routes.Settings.location().pathname} color="unset">
            {p.name}
          </InternalLink>
        </SettingsPresetBox>

        <div>
          <PresetDropdownList presets={p.presets} />
        </div>

        <PresetBox>
          {hasAdminAccess && (
            <InternalLink
              to={Routes.Settings.location().pathname}
              color="unset"
            >
              {tPlaceholder("Open preset")}
            </InternalLink>
          )}
        </PresetBox>
      </Fragment>
    )
  })

  const mobileRows = monitoringProfiles.map((p) => {
    const Icon = p.icon

    return (
      <MobileBox key={p.id}>
        <MobilePresetBox>
          <div>
            <Icon width={24} height={24} />
          </div>
          <div>
            <InternalLink
              to={Routes.Settings.location().pathname}
              color="unset"
            >
              {p.name}
            </InternalLink>

            <PresetDropdownList presets={p.presets} />
          </div>
        </MobilePresetBox>

        {hasAdminAccess && (
          <InternalLink to={Routes.Settings.location().pathname} color="unset">
            {tPlaceholder("Open preset")}
          </InternalLink>
        )}
      </MobileBox>
    )
  })

  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.XS}>
        {tPlaceholder("Monitoring presets")}
      </MText>
      <MText color="secondary">
        {tPlaceholder(
          "Monitoring presets contain pre-configured settings and can be applied to a subset of rental units."
        )}{" "}
        <ExternalLink color="secondary" href={"#"}>
          {t(langKeys.learn_more)}
        </ExternalLink>
      </MText>

      <TableWrapper>
        <GridTable
          header={header}
          rows={rows}
          mobileRows={mobileRows}
          templateColumns={`repeat(${header.length}, auto)`}
          horizontalScroll
        />
      </TableWrapper>
    </div>
  )
}

function PresetDropdownList({
  presets,
}: {
  presets: { id: string; name: string }[]
}) {
  return (
    <DropdownListButton
      displayValue={presets.find((p) => p.id)?.name ?? ""}
      variant="minimal"
      size="medium"
      options={presets.map((p) => ({
        name: p.name,
        value: p.id,
        onClick: () => {},
      }))}
    />
  )
}

const TableWrapper = styled.div`
  margin-block: ${spacing.L};
`

const PresetBox = styled.div`
  text-align: right;
`

const SettingsPresetBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};
`

const MobileBox = styled.div`
  display: flex;
  gap: ${spacing.L};
  align-items: center;
  justify-content: space-between;
`

const MobilePresetBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${spacing.XS};
  align-items: center;
`
